.card-content h3{
	font-size: 1rem;
	font-weight: 600;
	letter-spacing: 2px;
	line-height: 140%;
}

.card-content{
	text-align: left;
	padding: 10%;
}