.about-img {
    width: 145px;
    max-height: 145px;
    border-radius: 100%;
    align-items: center;
}

.about-img:hover {
    opacity: 0.5;
    transition: all 0.3s ease 0s;
}
