nav {
    z-index: 3000;
}
.navbar .nav-item .nav-link {
    font-family: "Rubik";
    font-weight: 600;
    color: black;
    transition: all 0.3s ease 0s;
}
.navbar .nav-item .nav-link:hover{
    color: #E57154;
}
.nav-link.active {
    color: #E57154;
  }
.navbar-brand img {
    width: 70px;
}
.navbar {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background: #F4D089; */
    padding: .5rem 1rem;
}
.navbar .join-button{
    background-color:  #E57154;
    box-sizing: border-box;
	border-radius: 10px;
	border: none;
	padding: 10px 25px;
    display: inline-block;
    font-family: "Rubik";
    font-weight: 600;
    color:  white;
}
.navbar button:hover{
	background-color:  #0A2338;
	transition: all 0.3s ease 0s;
}
.navbar ul{
    justify-content: flex-end; 
    font-weight: 600;
    color: white;
}
.vertical-center{
    display:flex;
    align-items: center;
}