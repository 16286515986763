:root {
	--mainColor: #e69f91;
  }
  
.buttonHover {
	font-weight: 500;
	line-height: 160%;
	background:
		linear-gradient(
			to bottom, var(--mainColor) 0%,
			var(--mainColor) 100%
		);
	background-position: 0 100%;
	background-repeat: repeat-x;
	background-size: 4px 4px;
	color: #000;
	text-decoration: none;
	transition: background-size .6s;
}
  
.buttonHover:hover {
	background-size: 4px 50px;
	text-decoration: none;
	color: #4B5D68;
	transition: 0.3s;
}


/* img {
	width: 100%;
} */