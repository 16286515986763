@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700&family=Rubik:wght@400;500;600;700&display=swap');

html {
    overflow: hidden;
    height: 100%;
}

body {
    height: 100%;
    overflow: auto;
}

/*Page header*/
h1{
    color: #0A2338;
	font-family: Helvetica;
	font-style: normal;
	font-weight: bold;
    line-height: 110%;
    font-size: 3.5rem;
}

/*Section headers*/
h2{
    font-family: Helvetica;
	font-style: normal;
	font-weight: bold;
	line-height: 140%;
	color: #402B2B;
}

/*orange rubik mini headers*/
h3{
    font-family: "Rubik";
	font-style: normal;
    font-weight: 700;
    font-size: 1.2rem;
    color: #E57154;
    line-height: 40px;
}

/*subheadings*/
h4{
    font-family: Helvetica;
	font-style: normal;
	font-weight: 600;
	line-height: 180%;
	color: #0A2338;
}

/*paragraph text in the header*/
h5{
    font-weight: 400;
    font-family: "Nunito Sans";
    color: #4B5D68;
}

h6{
    font-weight: 700;
    font-family: "Nunito Sans";
    font-size: 16px;
    color: #0A2338;
}

p{
    color: #0A2338;
    font-weight: 400;
    font-family: "Nunito Sans";
    font-size: 16px;
    color: #0A2338;
}

/*alignment classes*/
.vertical-center{
    display:flex;
    align-items: center;
}

.horizontal-center{
    text-align: center;
}

.center {
    margin: auto;
    width: 50%;
    padding: 10px;
}

/*margin classes*/
.marginTop5Footer{
	margin-top: 5%;
}

.marginTop10{
	margin-top: 10%;
}

.marginTop20{
	margin-top: 20%;
}

.marginTop30{
	margin-top: 30%;
}

/*buttons*/
.round-orange-button{
    border-radius: 50px;
    color: white;
    background-color: #E68976;
    font-family: "Nunito Sans";
    font-weight: 600;
    font-size: 16px;
    padding: 7px 20px;
    margin: 5px 0;
    border: none;
}

.round-orange-button:hover{
    opacity: 0.5;
    transition: all 0.3s ease 0s;
}

/*event pills*/
.yellow-pill {
	background-color: #F4D089;
  	border-radius: 20px;
  	width: 90px;
	height: 10px;
	margin: 15px 0;
}

.red-pill {
	background-color: #E68976;
  	border-radius: 20px;
  	width: 90px;
	height: 10px;
	margin: 15px 0;
}

.beige-pill{
    background-color: #F1EFE2;
  	border-radius: 20px;
  	width: 90px;
	height: 10px;
	margin: 15px 0;
}

.event-card{
    box-shadow: 0 4px 8px 0 rgba(255,0,0,0.2);
	transition: 0.3s;
	background: white;
	border-radius: 20px;
	width: 33%;
	overflow: hidden;
}

.card-content h3{
	font-size: 1rem;
	font-weight: 600;
	letter-spacing: 2px;
	line-height: 140%;
}

.card-content{
	text-align: left;
	padding: 10%;
}

.card-img{
	width: 200px;
	height: 200px;
	padding: 5% 5%;
}

.slack-img {
	text-align: right;
	padding: 0 0 4px 0;
}

.slack-img img {
	width: 30%;
}

.slack-text {
	text-align: left;
}

/* mobile responsive */

@media (max-width:1000px){
    .slack-img {
		text-align: center;
	}

	.slack-img img{
		width: 20%;
	}
	
	.slack-text {
		text-align: center;
	}
	.slack-text {
		text-align: center;
	}
}

@media (max-width:991px){
    .reverse-col{
       flex-direction: column-reverse;
       padding:5%;
   }
}


@media (max-width: 568px){
	.mobileimg{
		width: 80%;
	}

	.mobileimgbig{
		width: 100%;
	}

	.mobileimg2{
		max-width: 90%;
	}

	/* .slick-track {
		max-width: 500px !important;
		max-height: 500px !important;
	}

	.slick-slide {
		max-width: 500px !important;
		max-height: 500px !important;
	}

	 .slick-cloned{
		max-width: 500px !important;
		max-height: 500px !important;
	} */

	i{
		font-size: 10vw;
	}

	.past-partners-slider{
		padding-bottom: 13%;
	}
	.card-img{
		width: 170px;
		height: 170px;
		padding: 3%;
	}
}


.partner-card{
    box-shadow: 0 4px 8px 0 rgba(255,0,0,0.2);
	transition: 0.3s;
	background: white;
	border-radius: 20px;
	overflow: hidden;
	height: 100%;
}

.partner-card:hover {
	box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}